<template>
  <div
    class="et-users-group px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <Paragraph
      ><p></p>
      <center>
        <a
          href="http://www.etusersgroup.org"
          target="_new"
          rel="noopener noreferrer"
          ><img
            src="@/assets/Resources/etuglogo-retina-min.png"
            alt="ET Users' Group Website Logo"
            width="400"
        /></a>
      </center>
      <br />
      Safety Management Services, Inc. chairs the ET Users’ Group consisting of
      multiple national laboratories, government facilities, and private
      entities. We meet annually to accomplish the objectives of the Charter
      given below. The ET Users’ Group is open to accepting new members who can
      collaborate with us and the other members to accomplish the below
      objectives. Please contact Jack Zarbock of Safety Management Services,
      Inc. for further information or visit the ET Users’ Group Website at
      <a
        href="http://www.etusersgroup.org"
        target="_new"
        rel="noopener noreferrer"
        >www.etusersgroup.org</a
      >.
      <p></p>
      <h4
        data-fontsize="13"
        data-lineheight="19.5px"
        class="fusion-responsive-typography-calculated"
        style="--fontSize:13; line-height: 1.5; --minFontSize:13;"
      >
        Charter
      </h4>
      <p>
        The Members of the ET Users’ Group will collaborate to systematically
        minimize the variability associated with energetic materials testing to
        enable consistent/repeatable test data and interpretation of test
        results. This will be accomplished by:<br />
        1) Developing procedures and methods<br />
        2) Applying technologies<br />
        3) Reaching consensus<br />
        4) Performing periodic “Round Robin” test series on standard materials
      </p>
    </Paragraph>
  </div>
</template>

<script>
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "ET Users Group",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS chairs the ET Users' Group, an organization consisting of multiple national labs, government facilities, and private entities. This group collaborates to minimize variability in energetic materials testing to enable consistent and repeatable test data and analysis."
      }
    ]
  },
  components: {
    Paragraph
  }
};
</script>

<style lang="scss">
.et-users-group {
  a {
    color: #aa222c;
  }
}
</style>
